import { Route, Routes, useLocation } from "react-router-dom"

import { useQuery, gql } from "@apollo/client"

import Sidebar from "components/Sidebar"
import { Home } from "pages/home/home"
import TenantDashboard from "pages/customer/dashboard"
import TenantDetails from "pages/CustomerDetails/customerDetails"
import TenantWasteTypes from "pages/tenantWasteTypes/tenantWasteTypes"
import HeaderBar from "UI/Header"
import { Loading } from "./App"
import "./Main.css"
import CircularityOverview from "pages/circularity/overview"
import Reporting from "pages/reporting"
import DownstreamHandling from "pages/circularity/downstream"
import ManageInfrastructure from "pages/infrastructure/manage"
import Upgrades from "pages/infrastructure/upgrades"
import { CostForecasts } from "pages/circularity/costs/costForecast"
import { Events } from "pages/events/events"
import NotFound from "pages/error/NotFound"
import Unauthorized from "pages/error/Unauthorized"
import ManageCustomers from "pages/customer/manage"
import { useEffect, useMemo, useState } from "react"
import { Terminal, useTerminalsState } from "States/Terminals"
import ErrorNotice from "components/ErrorNotice"
import { getPermittedTerminals, hasAnyTerminalAccess, isCarrotAdmin } from "Utils/authUtils"
import { Configuration } from "pages/configuration"
import { useConfig } from "api/hooks/useConfig"
import { translationContext } from "States/translationContext"
import { Allocations } from "pages/allocations/allocations"
import PrintQRCodesPage from "pages/qr/PrintQRCodesPage"
import { trpc } from "Utils/trpc"
import { Logout } from "pages/logout"
import { ImportInfrastructure } from "pages/infrastructure/import"
import { ImportTenants } from "pages/customer/manage/components/import"

const GET_NUM_REQUESTS = gql`
	{
		numRequests @client
	}
`

const LoadingIndicator = () => {
	const { data } = useQuery(GET_NUM_REQUESTS)
	return (
		<svg
			className={(data?.numRequests ?? 0) > 0 ? "active" : ""}
			viewBox="0 0 100 1"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line x1="0" y1="0" x2="100" y2="0" />
		</svg>
	)
}

const Main: React.FC = () => {
	const { currentTerminal, setTerminalState } = useTerminalsState()
	const { config, isConfigLoading, isRealEstate, isMWM } = useConfig()
	const { setContext } = translationContext()
	const location = useLocation()

	const { data: terminals, isLoading: loading, error } = trpc.getAllTerminals.useQuery()

	const terminalId = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const terminalIdFromUrl = urlParams.get("terminal-id")
		const terminalInStorage = localStorage.getItem("terminalId")
		if (terminalIdFromUrl) {
			localStorage.setItem("terminalId", terminalIdFromUrl)
			return terminalIdFromUrl
		} else if (terminalInStorage) {
			return terminalInStorage
		}
	}, [])

	useEffect(() => {
		const filteredTerminals = getPermittedTerminals(terminals, isMWM)

		let newTerminal

		if (currentTerminal?.id) {
			newTerminal = currentTerminal
		} else if (filteredTerminals?.length === 1) {
			newTerminal = filteredTerminals[0]
		} else {
			newTerminal =
				filteredTerminals?.find((re: Terminal) => re.id === terminalId) ?? filteredTerminals?.[0]
		}

		if (newTerminal) {
			setTerminalState({
				currentTerminal: newTerminal,
				terminals: filteredTerminals,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [terminals])

	useEffect(() => {
		config?.type && setContext(config?.type)
	}, [config?.type, setContext])

	const [showSidebar, setShowSidebar] = useState(false)

	useEffect(() => {
		setShowSidebar(false) // close sidebar on route change
	}, [location])

	const toggleMenu = () => {
		setShowSidebar(!showSidebar)
	}

	// Wait for useEffect to set terminalId (if Real Estate)
	const isSettingTerminal = useMemo(
		() => isRealEstate && !currentTerminal?.id && !!terminals?.length,
		[isRealEstate, currentTerminal, terminals]
	)

	if (loading || isConfigLoading || (hasAnyTerminalAccess() && isSettingTerminal)) {
		console.log("Waiting for terminals/config to load")
		return <Loading />
	}

	if (error?.message.includes("401")) return <Unauthorized />

	return (
		<>
			<div id="Main" className="grid md:grid-cols-main">
				<LoadingIndicator />
				<Sidebar showSidebar={showSidebar} toggleMenu={toggleMenu} />
				<main className="grid min-h-screen grid-cols-1 grid-rows-main bg-grey1 transition duration-200 ease-in-out print:min-h-0">
					<HeaderBar toggleMenu={toggleMenu} />
					<MainView isError={!!error} />
				</main>
			</div>
		</>
	)
}

const MainView: React.FC<{ isError: boolean }> = ({ isError }) => {
	const isAdmin = isCarrotAdmin()
	const { config, isMWM } = useConfig()

	if (isError) return <ErrorNotice />

	return (
		<div className="row-start-2">
			<Routes>
				<Route path="/" element={<Home />} />
				{!isMWM && <Route path="/reporting" element={<Reporting />} />}
				<Route path="/events" element={<Events />} />
				{!isMWM && (
					<>
						<Route path="/tenant/dashboard" element={<TenantDashboard />} />
						<Route path="/tenant/dashboard/details" element={<TenantDetails />} />
						<Route path="/tenant/dashboard/waste-types" element={<TenantWasteTypes />} />
					</>
				)}
				<Route path="/circularity/overview" element={<CircularityOverview />} />
				<Route path="/circularity/downstream" element={<DownstreamHandling />} />
				<Route path="/circularity/costs" element={<CostForecasts />} />
				{((isMWM && !config?.useExternalCRM) || isAdmin) && (
					<Route path="customer/manage/import" element={<ImportTenants />} />
				)}
				<Route path="/customer/manage/:id?" element={<ManageCustomers />} />
				{(isMWM || isAdmin) && (
					<Route path="/infrastructure/manage/import" element={<ImportInfrastructure />} />
				)}
				<Route
					path="/infrastructure/manage/:terminalId?/:accessParentId?/:containerId?"
					element={<ManageInfrastructure />}
				/>
				<Route path="/infrastructure/upgrades" element={<Upgrades />} />
				{(!isMWM || isAdmin) && <Route path="/settings" element={<Configuration />} />}
				<Route path="/infrastructure/manage/qr-codes" element={<PrintQRCodesPage />} />
				{isMWM && config?.useExternalCRM && (
					<Route path="/allocations/:id?" element={<Allocations />} />
				)}
				<Route path="/logout" element={<Logout />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	)
}

export default Main
