import {
	CardFilterTimeFrameIso,
	TimeFilterCustomDate,
	getCustomTimeFrame,
	getTimeFrame,
} from "components/pageCards/filterSort/filterTimeFrame"
import { useAccessParents } from "pages/infrastructure/functions"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import {
	createCustomerFilter,
	createAccessParentFilter,
	createTerminalFilter,
	createResultCodeFilter,
	createDepotFilter,
	createEventTypeFilter,
} from "./filters"
import { trpc } from "Utils/trpc"
import { Terminal, useTerminalsState } from "States/Terminals"
import { SortingState } from "@tanstack/react-table"
import { EVENT } from "pages/events/types"
import { EmptyEventDto, ListEventsDtoItemsInner, UseEventDto } from "admin-client-server/src/api"
import { uniq, upperCase } from "lodash"
import { useTrans } from "translations"
import {
	ACCESS_PARENT,
	IsoIdentityWithoutStatus,
} from "admin-client-server/src/coreApi/models/Common"
import { TOptions } from "i18next"
import { useConfig } from "../useConfig"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import {
	createTimeFilters,
	createFractionFilter,
	FilterTimeFrameValue,
} from "components/pageCards/filterSort/filterCreators"
import { hasNonAllOptionActive } from "components/pageCards/filterSort/constants"
import { EVENT_SORT_FIELDS } from "admin-client-server/src/routers/events"
import { parseSearchParamsForFilterOptions } from "components/pageCards/filterSort/searchParams"
import { useLocation } from "react-router-dom"
import { MinimalCustomer } from "admin-client-server/src/coreApi/models/RealEstate"
import { useConfigService } from "pages/configuration/useConfigService"
import { useAuth0 } from "Contexts"
import { downloadExportFile } from "api/export"
import { useGlobalAlert } from "States/globalAlert"

type EVENT_SORT_FIELDS_TYPE = (typeof EVENT_SORT_FIELDS)[number]

const sortFieldsMap = (key: string | undefined) => {
	switch (key) {
		case "timestamp":
			return "timestamp"
		case "weight_quantity":
			return "weight"
	}
	return undefined
}

// Equivalent to ListEventsDtoItemsInner.TypeEnum on the server
export enum EVENT_TYPE {
	USE = "use",
	EMPTY = "empty",
}

type EventContainers = {
	accessParent: {
		id: string
		name?: string
		parentId?: string
	}
	id: string
	type: string
	name?: string
	isDepot?: boolean
	depot?: {
		id: string
		name: string
	}
}

const mapEvent = (
	event: ListEventsDtoItemsInner,
	customerList: MinimalCustomer[],
	terminals: Terminal[],
	accessParents: ACCESS_PARENT[],
	t: (key: string, options?: TOptions) => string,
	allContainers: EventContainers[],
	identities?: IsoIdentityWithoutStatus[]
): EVENT => {
	let innerEvent: (UseEventDto | EmptyEventDto) & { type: string }

	// Cannot be a switch statement because TS won't recognize the type narrowing
	if (event.type === EVENT_TYPE.USE) {
		innerEvent = { ...event.useEvent, type: "Throw" }
	} else if (event.type === EVENT_TYPE.EMPTY) {
		innerEvent = { ...event.emptyEvent, type: "Empty" }
	} else {
		throw new Error("Unknown event type in useEvents.tsx.")
	}

	const { customer, point, result, identity } = innerEvent as Partial<UseEventDto>

	let container
	let accessParent:
		| {
				id: string
				name?: string
				parentId?: string
		  }
		| undefined
	let depot
	let terminal

	const terminalWithEvent = terminals.find(t => t.id === point?.id)
	if (terminalWithEvent) {
		terminal = terminalWithEvent
	}

	const apWithEvent = accessParents.find(ap => ap.id === point?.id)
	if (apWithEvent) {
		accessParent = apWithEvent
		terminal = terminals.find(t => t.id === apWithEvent.parentId)
	}

	if (!terminalWithEvent && !apWithEvent) {
		container = allContainers.find(c => c.id === point?.id)

		accessParent = container?.accessParent

		depot = (container?.isDepot && container) || container?.depot || null

		terminal = terminals?.find(({ id }) => id === accessParent?.parentId)
	}

	const extraCustomerData = customerList?.find(({ id }) => id === customer?.id)

	return {
		...innerEvent,
		customer: {
			id: customer?.id,
			name: extraCustomerData?.name,
			crmId: extraCustomerData?.crmId,
		},
		accessParent: {
			id: accessParent?.id,
			name: accessParent?.name,
		},
		terminal: terminal
			? {
					id: terminal?.id,
					name: terminal?.name,
				}
			: undefined,
		depot: depot ? { id: depot?.id, name: depot?.name } : undefined,
		container: !container?.isDepot
			? {
					id: point?.id,
					name: container?.name,
				}
			: undefined,
		identity: {
			id: identity?.id,
			...identities?.find(({ id }: { id: string }) => id === identity?.id),
		},
		result,
		timestamp: new Date(`${innerEvent.timestamp}`).getTime(),
		type: t(`eventTypes:${upperCase(innerEvent.type)}`) ?? t("common:unknown"),
	} as EVENT
}

const mapEventId = (event: ListEventsDtoItemsInner) => {
	// Cannot be a switch statement because TS won't recognize the type narrowing
	if (event.type === EVENT_TYPE.USE) {
		return event.useEvent?.id
	} else if (event.type === EVENT_TYPE.EMPTY) {
		return event.emptyEvent?.id
	} else {
		throw new Error("Unknown event type in useEvents.tsx.")
	}
}

type EventFilters =
	| "timeframe"
	| "customerId"
	| "accessParentId"
	| "filterTerminalId"
	| "depotId"
	| "fractionId"
	| "resultCode"
	| "eventType"

const defaultTimeframe = FilterTimeFrameValue.LAST_WEEK

const limit = 100

export const useEvents = () => {
	const { t, language } = useTrans()
	const { isMWM, config } = useConfig()
	const [allSelected, setAllSelected] = useState(false)
	const { terminals } = useTerminalsState()
	const { selectedEventIds, setSelectedEventIds } = useMultiSelectContext()
	const { wasteTypeConfig } = useConfigService()
	const { getTokenSilently } = useAuth0()!

	const { setGlobalAlert } = useGlobalAlert()

	const [filterState, setFilterState] = useState<ActiveOptions<EventFilters>>(
		{} as ActiveOptions<any>
	)

	const {
		timeframe: [timeframe] = [],
		customerId,
		accessParentId,
		filterTerminalId,
		depotId,
		fractionId,
		resultCode,
		eventType,
	} = filterState

	const { startTimeISO, endTimeISO } = useMemo(() => {
		if (timeframe?.option === "interval.custom") {
			return getCustomTimeFrame(timeframe.value as TimeFilterCustomDate)
		} else {
			return getTimeFrame((timeframe?.value as FilterTimeFrameValue) ?? defaultTimeframe)
		}
	}, [timeframe])

	const location = useLocation()
	const [sortingState, setSortingState] = useState<SortingState>()
	const { currentTerminal } = useTerminalsState()

	// Support filtering by containerId without having a UI for it.
	// Used for linking to events from the container page.
	const containerId = useMemo(() => {
		const searchParams = location.search
		const urlFilters = parseSearchParamsForFilterOptions(searchParams)
		return urlFilters?.["containerId"]
	}, [location.search])

	// Filter by accessParentId, terminalId, or default to currentTerminal if not MWM
	const pointIds = useMemo(() => {
		if (containerId) {
			return [containerId]
		}
		if (hasNonAllOptionActive(depotId)) {
			return depotId.map(({ value }) => value)
		}
		if (hasNonAllOptionActive(accessParentId)) {
			return accessParentId.map(({ value }) => value)
		}
		if (isMWM && hasNonAllOptionActive(filterTerminalId)) {
			return filterTerminalId.map(({ value }) => value)
		}
		return isMWM ? undefined : [currentTerminal.id]
	}, [currentTerminal.id, isMWM, accessParentId, filterTerminalId, depotId, containerId])

	const queryParams = useMemo(() => {
		const sortField = sortFieldsMap(sortingState?.[0]?.id)
		return {
			startTime: startTimeISO,
			endTime: endTimeISO,
			pointIds,
			customerIds: hasNonAllOptionActive(customerId)
				? customerId.map(({ value }) => value)
				: undefined,
			fractionCodes: hasNonAllOptionActive(fractionId)
				? fractionId.map(({ value }) => value)
				: undefined,
			sortDirection: sortingState?.[0]?.desc ? "desc" : ("asc" as "asc" | "desc"),
			sortFields: sortField ? [sortField as EVENT_SORT_FIELDS_TYPE] : undefined,
			resultCode: hasNonAllOptionActive(resultCode)
				? (resultCode[0].value as "accepted" | "rejected")
				: undefined,
			eventTypes: hasNonAllOptionActive(eventType) ? [eventType[0].value as EVENT_TYPE] : undefined,
		}
	}, [
		sortingState,
		startTimeISO,
		endTimeISO,
		pointIds,
		customerId,
		fractionId,
		resultCode,
		eventType,
	])

	const {
		data: eventData,
		refetch: fetchEvents,
		isRefetching: isRefetchingEvents,
		isFetching: isLoadingEvents,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		isError,
		error,
	} = trpc.events.getAll.useInfiniteQuery(
		{ ...queryParams, limit },
		{
			getNextPageParam: lastPage => lastPage?.nextCursor ?? undefined,
			onSuccess(data) {
				if (data.pages.length === 1) {
					setSelectedEventIds([])
					return
				}
				const items = data.pages.flatMap(page => page.items)
				if (allSelected) {
					setSelectedEventIds(items.map(event => mapEventId(event)))
				}
			},
			enabled: !!(filterState && sortingState),
		}
	)

	const { data: customerList = [], isLoading: isLoadingCustomers } =
		trpc.customers.getAllMinimal.useQuery({
			terminalId: isMWM ? undefined : currentTerminal.id,
		})

	const { accessParents, isLoading: isLoadingPointList } = useAccessParents()

	const { wasteTypes } = useCommonEntitiesStore()

	const identityIds: string[] = useMemo(() => {
		if (!isMWM) {
			return []
		}

		return uniq(
			eventData?.pages
				.flatMap(page => page.items)
				.flatMap(event =>
					event.type === EVENT_TYPE.USE ? event.useEvent?.identity?.id : undefined
				)
				.filter(id => id) ?? []
		)
	}, [eventData, isMWM]) as string[]

	const { data: identities } = trpc.identities.getIdentitiesByIds.useQuery(
		{
			ids: identityIds,
		},
		{
			enabled: isMWM && !!identityIds.length,
		}
	)
	const allContainers = useMemo(
		() => [
			...accessParents.flatMap(
				// all access parent containers
				ap =>
					ap.containers?.map(c => ({
						...c,
						accessParent: {
							id: ap.id,
							name: ap.name,
							parentId: ap.parentId,
						},
					})) || []
			),
			...accessParents.flatMap(
				// all depots
				ap =>
					ap.depots?.map(d => ({
						...d,
						isDepot: true,
						type: "GROUP",
						accessParent: {
							id: ap.id,
							name: ap.name,
							parentId: ap.parentId,
						},
					})) || []
			),
			...accessParents.flatMap(
				// all containers on depots
				ap =>
					ap.depots?.flatMap(
						d =>
							d.containers?.map(c => ({
								...c,
								accessParent: {
									id: ap.id,
									name: ap.name,
									parentId: ap.parentId,
								},
								depot: {
									id: d.id,
									name: d.name,
								},
							})) || []
					) || []
			),
		],
		[accessParents]
	)

	const joinedEventData: EVENT[] = useMemo(() => {
		return (
			eventData?.pages
				.flatMap(page => page.items)
				.map(event =>
					mapEvent(event, customerList, terminals, accessParents, t, allContainers, identities)
				) ?? []
		)
	}, [eventData, customerList, terminals, accessParents, t, identities, allContainers])

	const areAllSelected = useMemo(() => {
		return joinedEventData.length === selectedEventIds.length
	}, [joinedEventData, selectedEventIds])

	const { data: wasteTypesTotal = [], isLoading: isLoadingWasteTypes } =
		trpc.getWasteTypes.useQuery({
			id: isMWM ? undefined : currentTerminal.id,
		})

	const isLoadingFilters = useMemo(
		() => isLoadingCustomers || isLoadingPointList || isLoadingWasteTypes,
		[isLoadingCustomers, isLoadingPointList, isLoadingWasteTypes]
	)
	const isLoading = useMemo(
		() => isLoadingFilters || (isLoadingEvents && !isFetchingNextPage),
		[isLoadingEvents, isLoadingFilters, isFetchingNextPage]
	)

	useEffect(() => {
		if (!isLoadingFilters && !eventData) fetchEvents()
	}, [eventData, fetchEvents, isLoadingFilters, startTimeISO, endTimeISO])

	const filteredAccessParents = useMemo(() => {
		if (hasNonAllOptionActive(filterTerminalId)) {
			return accessParents.filter(({ parentId }) => parentId === filterTerminalId[0].value)
		}
		return accessParents
	}, [accessParents, filterTerminalId])

	const filteredDepots = useMemo(() => {
		if (hasNonAllOptionActive(accessParentId)) {
			return filteredAccessParents.find(({ id }) => id === accessParentId[0].value)?.depots
		}
		return filteredAccessParents.flatMap(({ depots }) => depots ?? [])
	}, [accessParentId, filteredAccessParents])

	const exportFile = useCallback(
		async (type: "csv" | "excel") => {
			await downloadExportFile({
				type,
				entity: "events",
				queryParams,
				terminalId: isMWM ? undefined : currentTerminal.id,
				getTokenSilently,
				language,
				t,
				showPeriodInTitle: true,
				showTimestampInTitle: true,
				selectedEventIds: areAllSelected ? undefined : selectedEventIds,
			})
		},
		[
			currentTerminal,
			queryParams,
			getTokenSilently,
			language,
			t,
			isMWM,
			selectedEventIds,
			areAllSelected,
		]
	)

	const filters: FilterSort[] = useMemo(() => {
		return [
			createTimeFilters({
				id: "timeframe",
				menuType: FilterSortMenuType.TimeFrame,
				defaultValue: defaultTimeframe,
			}),
			createEventTypeFilter(),
			createCustomerFilter(customerList),
			isMWM && createTerminalFilter(terminals),
			createAccessParentFilter(filteredAccessParents),
			config?.useWasteSuctionSystem && createDepotFilter(filteredDepots),
			createFractionFilter(wasteTypesTotal, wasteTypes, wasteTypeConfig),
			createResultCodeFilter(),
		].filter(Boolean) as FilterSort[]
	}, [
		customerList,
		isMWM,
		terminals,
		filteredAccessParents,
		filteredDepots,
		wasteTypesTotal,
		wasteTypes,
		config,
		wasteTypeConfig,
	])

	const { mutate: updateEventMutation } = trpc.events.update.useMutation()

	const editEvent = useCallback(
		async ({ id, weightKg, pointId }: { id: string; weightKg?: number; pointId?: string }) => {
			await updateEventMutation(
				{
					id,
					weightKg,
					pointId,
				},
				{
					onSuccess: () => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:changesSaved",
						})
						fetchEvents()
					},
				}
			)
		},
		[updateEventMutation, fetchEvents, setGlobalAlert]
	)

	const { mutate: deleteEventMutation } = trpc.events.delete.useMutation()

	const deleteEvent = useCallback(
		async ({ id }: { id: string }) => {
			await deleteEventMutation(
				{
					id,
				},
				{
					onSuccess: () => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:rowDeleted",
						})
						fetchEvents()
					},
				}
			)
		},
		[fetchEvents, deleteEventMutation, setGlobalAlert]
	)

	return {
		data: joinedEventData || [],
		filters,
		isLoading,
		isLoadingFilters,
		isError,
		error: error as Error | null | undefined,
		timeframe: { startTimeISO, endTimeISO } as CardFilterTimeFrameIso,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		sortingState,
		setSortingState,
		identities,
		selectedEventIds,
		setSelectedEventIds,
		filterState,
		setFilterState,
		setAllSelected,
		exportFile,
		startTimeISO,
		endTimeISO,
		areAllSelected,
		editEvent,
		deleteEvent,
		isRefetchingEvents,
	}
}
